<template>
    <section class="xbts-withdraw">
        <div v-if="this.$root.pin" class="card w-100">
            <div class="card-body">
                <!--<b-breadcrumb v-show="!this.$root.isMobile" :items="breadcrumbs" class="breadcrumb"/>-->
                <p class="text-uppercase"><router-link to="/">XBTS</router-link> <i class="mdi mdi-chevron-right"></i> <router-link to="/wallet">{{$t('wallet.wallet')}}</router-link> <i class="mdi mdi-chevron-right"></i> <span>{{$t('wallet.withdraw')}}</span></p>
                <hr/>
                <WithdrawCrypto/>
            </div>
        </div>
    </section>
</template>

<script>
import WithdrawCrypto from '@/components/wallet/WithdrawCrypto';

export default {
    name: "WalletWithdraw",
    components: {
        WithdrawCrypto,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: "XBTS",
                    href: "/#/"
                },
                {
                    text: "Wallet",
                    href: "#/wallet"
                },
                {
                    text: "Withdraw Crypto",
                    active: true
                },
            ],
        }
    },
    async created() {
        if (!this.$root.pin) {
            await this.$router.push('/auth-pages/login');
        }
    }
}
</script>

<style>

</style>
