<template>
  <div>

    <div class="row">
      <div class="col-md-6">
        <table class="table table-borderless">
          <tbody>
          <tr>
            <td>{{ $t('wallet.total') }}</td>
            <td>
                <span
                    class="text-white mr-1">{{ formatNum(balances[asset].total) }}</span>
              <span v-html="formatAssetPrefix(asset).html"></span>
            </td>
          </tr>
          <tr>
            <td>{{ $t('wallet.order') }}</td>
            <td>
                <span
                    class="text-white mr-1">{{ formatNum(balances[asset].limit_orders) }}</span>
              <span v-html="formatAssetPrefix(asset).html"></span>
            </td>
          </tr>
          <tr>
            <td>{{ $t('wallet.available') }}</td>
            <td>
                                    <span @click="amount = balances[asset].amount - 1" class="text-success cursor-pointer mr-1">
                                        {{ formatNum(balances[asset].amount) }}
                                    </span>
              <span v-html="formatAssetPrefix(asset).html"></span>
            </td>
          </tr>

          <tr>
            <td>{{ $t('app.support') }}</td>
            <td><a href="https://t.me/xbtsio" target="_blank">https://t.me/xbtsio</a></td>
          </tr>

          </tbody>
        </table>

      </div>
      <div class="col-md-6">
        <!-- STEP-1 -->
        <div v-show="step === 1">
          <b-form-group
              :label="$t('wallet.address_recipient') + ' BitShares Blockchain'"
              label-for="input_address">
            <span v-show="isScam" class="text-danger">Alert! Scam Detected</span>
            <b-input-group>
              <b-form-input :class="isValid.address ? 'is-valid' : 'is-invalid'"
                            @input="enterAddress()" class="form-control"
                            id="input_address"
                            placeholder=""
                            type="text"
                            v-model="address">
              </b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group
              :label="$t('wallet.amount')"
              label-for="input_amount">
            <b-input-group>
              <b-form-input :class="isValid.amount ? 'is-valid' : 'is-invalid'"
                            @input="checkAmount()" class="form-control"
                            id="input_amount"
                            placeholder=""
                            type="text"
                            v-model="amount">
              </b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group
              :label="$t('wallet.memo')"
              label-for="input_memo"
          >
            <b-input-group>
              <b-form-input @input="enterAddress()" class="form-control" id="input_memo"
                            placeholder=""
                            type="text"
                            v-model="memo">
              </b-form-input>
            </b-input-group>
          </b-form-group>



          <button
              :disabled="!isValid.address || !isValid.amount || isScam"
              @click="txPrepare"
              class="btn btn-primary btn-fw w-100 text-uppercase" type="button">
            {{ $t('app.continue') }}
          </button>


        </div>

        <!-- STEP 2 -->
        <div v-if="step === 2">
          <span class="text-danger" v-show="preparedTx.cost[Object.keys(preparedTx.cost)[0]] > balances[asset].amount">{{ $t('wallet.noFeeBalance') }}</span>
          <table class="table table-striped table-borderless w-100">
            <tbody>
            <tr>
              <td class="text-white">{{ $t('wallet.sender') }}</td>
              <td>{{ this.$root.account.account.name }}</td>
            </tr>
            <tr>
              <td class="text-white">{{ $t('wallet.recipient') }}</td>
              <td>{{ address }}</td>
            </tr>
            <tr>
              <td class="text-white">{{ $t('wallet.amount') }}</td>
              <td>{{ amount }} {{ asset }}</td>
            </tr>
            <tr>
              <td class="text-white">{{ $t('market.fee') }}</td>
              <td><span
                  v-if="preparedTx.cost">{{ preparedTx.cost[Object.keys(preparedTx.cost)[0]] }} {{ Object.keys(preparedTx.cost)[0] }}</span>
              </td>
            </tr>
            <tr>
              <td class="text-white">{{ $t('wallet.memo') }}</td>
              <td><span class="text-break text-small">{{ memo }}</span></td>
            </tr>
            </tbody>
          </table>


          <div class="row">
            <div class="col-md-6">
              <button
                  :disabled="preparedTx.cost > balances[asset].amount"
                  @click="txConfirm"
                  class="mt-3 btn btn-primary btn-fw w-100 text-uppercase"
                  type="button" v-if="preparedTx.cost">
                {{ $t('app.confirm') }}
              </button>
            </div>
            <div class="col-md-6">
              <button
                  @click="step = 1"
                  class="mt-3 btn btn-warning btn-fw w-100 text-uppercase"
                  type="button">
                {{ $t('app.cancel') }}
              </button>
            </div>

          </div>


        </div>

        <div v-show="step === 3">
          <atom-spinner
              v-if="!txResult"
              class="text-center"
              :animation-duration="1000"
              :size="120"
              :color="'#248afd'"
          />
          <div v-if="txResult">
            <table class="table table-striped table-borderless">
              <tbody>
              <tr>
                <td>Status</td>
                <td>Success</td>
              </tr>

              <tr>
                <td>id</td>
                <td>
                  <a :href="'https://ex.xbts.io/block/'+txResult[0].block_num+'/' + txResult[0].trx_num"
                     target="_blank">{{ txResult[0].id }}</a>
                </td>
              </tr>
              </tbody>
            </table>

            <button
                @click="step = 1"
                class="mt-3 btn btn-warning btn-fw w-100 text-uppercase"
                type="button">
              {{ $t('app.continue') }}
            </button>
          </div>
        </div>

        {{isProgress}}

      </div>
    </div>

  </div>
</template>

<script>
import {AtomSpinner} from 'epic-spinners';
export default {
  name: "SendAsset",
  components: {
    AtomSpinner,
  },
  data() {
    return {
      step: 1,
      txResult: null,
      isValid: {
        address: null,
        amount: null,
      },
      preparedTx: {
        tx: null,
        cost: null,
      },
      balances: {},
      balance: 0,
      asset: 'BTS',
      address: null,
      amount: null,
      memo: null,
      isScam: false,
    }
  },
  computed: {
    scamAccounts() {
      return this.$store.getters['wallet/scamAccounts'] || {};
    }
  },
  methods: {
    async checkScam() {
      this.isScam = this.scamAccounts[this.$root.account.account.name] || this.scamAccounts[this.address];
    },
    async enterAddress() {
      this.isValid.address = this.address ? true: false;
      await this.checkScam();
    },
    async checkAmount() {
      this.isValid.amount = this.balances[this.asset].amount-1 > this.amount;
      await this.checkScam();
    },
    async txPrepare() {
      if (this.isScam) {
        return
      }
      this.address = this.address.trim();
      this.step = 2;
      this.preparedTx = await this.$store.dispatch('transaction/prepare', {
        account: this.$root.account,
        recipient: this.address,
        asset: this.asset,
        amount: this.amount,
        memo: this.memo,
      });
    },
    async txConfirm() {
      this.step = 3;
      this.txResult = await this.$store.dispatch('transaction/broadcast', this.preparedTx.tx);


      if (this.txResult) {
        await this.notifySuccess('Transfer Success!' + ' ' + this.asset);
        this.balances[this.asset].amount = this.balances[this.asset].amount - this.preparedTx.cost[Object.keys(this.preparedTx.cost)[0]] - this.amount;
        this.balances[this.asset].total = this.balances[this.asset].amount + this.balances[this.asset].limit_orders;
        //this.balances = this.balancesBySymbol();
        await this.cleanUp();

      }

    },

    async cleanUp() {
      this.preparedTx = {
        tx: null,
        cost: null,
      };
      this.isValid.amount = null;
      this.amount = null;
      //this.memo = null;
    },
  },
  async created() {
    this.balances = this.balancesBySymbol();
    this.asset = this.$route.params.symbol;
  }

}
</script>

<style>
.atom-spinner {
  margin-left: auto;
  margin-right: auto;
}
</style>