<template>
  <div class="">
    <div v-if="symbol === 'BTS'">
      <SendAsset/>
    </div>

    <div v-if="gateways[protocol.type]">

      <div class="row">
        <div class="col-md-6 text-left">
          <button :disabled="step === 2" :class="protocol.type === 'coins' ? 'btn-primary' : 'btn-outline-primary'"
                  @click="setProtocol('coins')" class="ml-2 mt-1 btn "
                  title="Native Blockchain"
                  v-show="gateways.coins[symbol] && (symbol !== 'XBTSX.ETH' && symbol !== 'XBTSX.HT' && symbol !== 'XBTSX.BNB' && symbol !== 'XBTSX.WAVES' && symbol !== 'XBTSX.EOS')">
            COIN
          </button>
          <button :disabled="step === 2" :class="protocol.type === 'eth' ? 'btn-primary' : 'btn-outline-primary'"
                  @click="setProtocol('eth')" class="ml-2 mt-1 btn "
                  title="Ethereum network [ETH]" v-show="gateways.eth[symbol]">ETH
          </button>
          <button :disabled="step === 2" :class="protocol.type === 'eos' ? 'btn-primary' : 'btn-outline-primary'"
                  @click="setProtocol('eos')" class="ml-2 mt-1 btn "
                  title="EOS Chain" v-show="gateways.eos[symbol]">EOS
          </button>
          <button :disabled="step === 2" :class="protocol.type === 'bsc' ? 'btn-primary' : 'btn-outline-primary'"
                  @click="setProtocol('bsc')" class="ml-2 mt-1 btn "
                  title="Binance Smart Chain [BSC]" v-show="gateways.bsc[symbol]">BSC
          </button>
          <button :disabled="step === 2" :class="protocol.type === 'waves' ? 'btn-primary' : 'btn-outline-primary'"
                  @click="setProtocol('waves')" class="ml-2 mt-1 btn "
                  title="WAVES Platform [WAVES]" v-show="gateways.waves[symbol]">
            WAVES
          </button>
        </div>
        <div class="col-md-6 text-right text-small">
          <div v-if="gateways[protocol.type][symbol].contract">
            Contract
            <a :href="'https://etherscan.io/token/' + gateways[protocol.type][symbol].contract"
               target="_blank"
               v-if="protocol.type === 'eth'">{{ gateways[protocol.type][symbol].contract }}</a>
            <a :href="'https://bscscan.com/token/' + gateways[protocol.type][symbol].contract"
               target="_blank"
               v-if="protocol.type === 'bsc'">{{ gateways[protocol.type][symbol].contract }}</a>
          </div>

          <div v-if="gateways[protocol.type][symbol].id">
            Asset Id
            <a :href="'https://wavesexplorer.com/assets/' + gateways[protocol.type][symbol].id"
               target="_blank"
               v-if="protocol.type === 'waves'">{{ gateways[protocol.type][symbol].id }}</a>
            <a :href="'https://dev.pywaves.org/assets/' + gateways[protocol.type][symbol].id"
               target="_blank"
               v-if="protocol.type === 'waves'"><i
                class="ml-3 icon-share-alt"></i></a>
          </div>
        </div>
      </div>


      <hr/>
      <div class="row">
        <div class="col-md-6">
          <SelectCryptoDeposit/>
          {{ $t('wallet.transfer_network') }} <span class="text-white">{{ protocol.title }}</span>
          {{ gateways[protocol.type][symbol]['name'] }}
        </div>
        <div class="col-md-6 text-right">
                        <span v-if="gateways[protocol.type][symbol].maxAmount">
                            <span>
                                {{ $t('wallet.limit') }} {{ formatBalance(gateways[protocol.type][symbol].maxAmount * 1, gateways[protocol.type][symbol].precision) }} {{ gateways[protocol.type][symbol].backingCoin }}
                            </span>
                        </span>
          <span class="ml-1 wallet-icon">
                                <img
                                    :src="'images/assets/' + gateways[protocol.type][symbol]['backingCoin'].toLowerCase() + '.png'"/>
                            </span>
        </div>
      </div>
      <hr/>

      <div class="row">
        <div class="col-md-6">
          <!--
          { "symbol": "XBTSX.STH", "id": "1.3.4099", "precision": 6, "amount": 500000000, "limit_orders": 24575498982 }
          -->
          <table class="table table-borderless">
            <tbody>
            <tr>
              <td>{{ $t('wallet.total') }}</td>
              <td>
                <span
                    class="text-white mr-1">{{ formatBalance((balance.amount * 1 + balance.limit_orders * 1) / 10 ** balance.precision, balance.precision) }}</span>
                <span v-html="formatAssetPrefix(balance.symbol).html"></span>
              </td>
            </tr>
            <tr>
              <td>{{ $t('wallet.order') }}</td>
              <td>
                <span
                    class="text-white mr-1">{{ formatBalance((balance.limit_orders) / 10 ** balance.precision, balance.precision) }}</span>
                <span v-html="formatAssetPrefix(balance.symbol).html"></span>
              </td>
            </tr>
            <tr>
              <td>{{ $t('wallet.available') }}</td>
              <td>
                                    <span @click="setMax" class="text-success cursor-pointer mr-1">
                                        {{ formatBalance((balance.amount) / 10 ** balance.precision, balance.precision) }}
                                    </span>
                <span v-html="formatAssetPrefix(balance.symbol).html"></span>
              </td>
            </tr>
            <tr>
              <td>{{ $t('assets.issuer') }}</td>
              <td><span>{{ gateways[protocol.type][symbol].issuer }}</span></td>
            </tr>
            <tr>
              <td>{{ $t('app.support') }}</td>
              <td><a href="https://t.me/xbtsio" target="_blank">https://t.me/xbtsio</a></td>
            </tr>

            </tbody>
          </table>


          <!--{{gateways[protocol.type][symbol]}}-->
        </div>
        <div class="col-md-6">

          <div v-if="gateways[protocol.type][symbol]['withdrawalAllowed']">
            <span v-show="isScam" class="text-danger">Alert! Scam Detected</span>
            <div v-show="step === 1">
              <b-form-group
                  :label="$t('wallet.address_recipient')+' ' + gateways[protocol.type][symbol].backingCoin"
                  label-for="input_address">
                <b-input-group>
                  <b-form-input :class="isValid.address ? 'is-valid' : 'is-invalid'"
                                @input="enterAddress()" class="form-control"
                                id="input_address"
                                placeholder=""
                                type="text"
                                v-model="address">
                  </b-form-input>
                </b-input-group>
              </b-form-group>

              <b-form-group
                  :label="$t('wallet.memo')"
                  label-for="input_memo"
                  v-if="gateways[protocol.type][symbol].memoSupport">
                <b-input-group>
                  <b-form-input @input="enterAddress()" class="form-control" id="input_memo"
                                placeholder=""
                                type="text"
                                v-model="memo">
                  </b-form-input>
                </b-input-group>
              </b-form-group>

              <b-form-group
                  :label="$t('wallet.amount')"
                  label-for="input_amount">
                <b-input-group>
                  <b-form-input :class="isValid.amount ? 'is-valid' : 'is-invalid'"
                                @input="checkAmount()" class="form-control"
                                id="input_amount"
                                placeholder=""
                                type="text"
                                v-model="amount">
                  </b-form-input>
                </b-input-group>
              </b-form-group>

              <button
                  :disabled="!isValid.address || !isValid.amount || isScam"
                  @click="txPrepare"
                  class="btn btn-primary btn-fw w-100 text-uppercase" type="button">
                {{ $t('app.continue') }}
              </button>
            </div>

            <!-- STEP 2 -->
            <div v-if="step === 2">
                                <span class="text-danger"
                                      v-show="preparedTx.cost[Object.keys(preparedTx.cost)[0]] > balanceBts">{{ $t('wallet.noFeeBalance') }}</span>
              <table class="table table-striped table-borderless w-100">
                <tbody>
                <tr>
                  <td class="text-white">{{ $t('wallet.sender') }}</td>
                  <td>{{ this.$root.account.account.name }}</td>
                </tr>
                <tr>
                  <td class="text-white">{{ $t('wallet.recipient') }}</td>
                  <td>{{ gateways[protocol.type][symbol].issuer }}</td>
                </tr>
                <tr>
                  <td class="text-white">{{ $t('wallet.amount') }}</td>
                  <td>{{ amount }} {{ gateways[protocol.type][symbol]['backingCoin'] }}</td>
                </tr>
                <tr>
                  <td class="text-white">{{ $t('market.fee') }}</td>
                  <td><span
                      v-if="preparedTx.cost">{{ preparedTx.cost[Object.keys(preparedTx.cost)[0]] }} {{ Object.keys(preparedTx.cost)[0] }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-white">{{ $t('wallet.memo') }}</td>
                  <td><span class="text-break text-small">{{ totalMemo }}</span></td>
                </tr>
                </tbody>
              </table>


              <div class="row">
                <div class="col-md-6">
                  <button
                      :disabled="preparedTx.cost > balanceBts"
                      @click="txConfirm"
                      class="mt-3 btn btn-primary btn-fw w-100 text-uppercase"
                      type="button" v-if="preparedTx.cost">
                    {{ $t('app.confirm') }}
                  </button>
                </div>
                <div class="col-md-6">
                  <button
                      @click="step = 1"
                      class="mt-3 btn btn-warning btn-fw w-100 text-uppercase"
                      type="button">
                    {{ $t('app.cancel') }}
                  </button>
                </div>

              </div>


            </div>

            <!-- STEP 3 -->
            <div v-show="step === 3">
              <div v-if="txResult">
                <table class="table table-striped table-borderless">
                  <tbody>
                  <tr>
                    <td>Status</td>
                    <td>Success</td>
                  </tr>

                  <tr>
                    <td>id</td>
                    <td>
                      <a :href="'https://ex.xbts.io/block/'+txResult[0].block_num+'/' + txResult[0].trx_num"
                         target="_blank">{{ txResult[0].id }}</a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <button
                  @click="step = 1"
                  class="mt-3 btn btn-warning btn-fw w-100 text-uppercase"
                  type="button">
                {{ $t('app.continue') }}
              </button>
            </div>
            <!-- STEP 3 END -->

            <p class="mt-2 small">
              <i class="icon-bulb"></i> {{ $t('wallet.min_withdraw') }}
              <span class="text-white">{{
                  (gateways[protocol.type][symbol].minAmount / 10 **
                      gateways[protocol.type][symbol].precision).toFixed(gateways[protocol.type][symbol].precision)
                }}</span>
              {{ gateways[protocol.type][symbol]['backingCoin'] }}
            </p>

            <p class="mt-2 small font-weight-bolder text-warning" v-show="amountToReceive > 0">
              <i class="icon-bulb"></i> {{ $t('wallet.recipient_receive') }}
              <span>
                                    <span
                                        class="text-white">{{ (amountToReceive).toFixed(gateways[protocol.type][symbol].precision) }}</span> {{ gateways[protocol.type][symbol]['backingCoin'] }}
                                </span>
            </p>

            <p class="mt-2 small" v-show="step === 1">
              <i class="icon-bulb"></i> {{ $t('wallet.tx_fees') }}
              <span class="text-white">{{ gateways[protocol.type][symbol].gateFee }}</span>
              {{ gateways[protocol.type][symbol]['backingCoin'] }}
            </p>

          </div>

          <div v-if="!gateways[protocol.type][symbol]['withdrawalAllowed']">
            <b-alert class="small" show variant="warning">
              <i class="icon-bulb"></i> Withdrawal on Maintanance
            </b-alert>
          </div>
        </div>
      </div>


      <div class="">
        <div class="position-relative text-small">

        </div>
      </div>


    </div>
  </div>
</template>

<script>
//import Transfer from '@/components/wallet/Transfer';
import SelectCryptoDeposit from '@/components/wallet/SelectCryptoDeposit';
import eventBus from "@/plugins/event-bus";
import SendAsset from '@/components/wallet/SendAsset';

export default {
  name: "WithdrawCoin",
  components: {
    SelectCryptoDeposit,
    SendAsset,
  },
  data() {
    return {
      step: 1,
      txResult: null,
      preparedTx: {
        tx: null,
        cost: null,
      },
      address: null,
      amount: null,
      amountToReceive: 0,
      cost: null,
      memo: null,
      totalMemo: "",
      w_memo: "",
      isValid: {
        address: null,
        amount: null,
      },
      protocol: {
        type: null,
        title: null,
      },
      symbol: null,
      isScam: false,
    }
  },
  methods: {
    async checkScam() {
      //this.isScam = this.scamAccounts[this.$root.account.account.name] ? true: false;
      this.isScam = !!this.scamAccounts[this.$root.account.account.name];
    },
    async cleanUp() {
      this.preparedTx = {
        tx: null,
        cost: 1,
      };
      this.isValid.amount = null;
      this.amount = null;
      this.amountToReceive = 0;
      this.totalMemo = "";
      //this.w_memo = "";

    },
    async setMax() {
      this.amount = this.balance.amount / 10 ** this.balance.precision
      await this.checkAmount();
    },
    async txPrepare() {
      if (this.isScam) {
        return
      }
      this.totalMemo = this.w_memo + this.address + (this.memo ? ":" + this.memo : "")
      this.step = 2;
      await this.$store.dispatch('wallet/saveAddress', {
        network: this.protocol.type,
        symbol: this.symbol,
        address: this.address,
        memo: this.memo,
      });
      this.preparedTx = await this.$store.dispatch('transaction/prepare', {
        account: this.$root.account,
        recipient: this.gateways[this.protocol.type][this.symbol].issuer,
        asset: this.symbol,
        amount: this.amount,
        memo: this.totalMemo,
      });
    },
    async txConfirm() {
      this.step = 3;

      //this.txResult = null; //test
      this.txResult = await this.$store.dispatch('transaction/broadcast', this.preparedTx.tx);

      this.$snotify.success('Transfer Success!', {
        timeout: 1000,
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
      });

      if (this.txResult) {
        setTimeout(async () => {
          await this.$store.dispatch('wallet/gateways');
        }, 6000);
      }

      await this.cleanUp();
    },
    async checkAmount() {
      if (!this.amount) {
        return
      }
      this.address = this.address.trim();
      await this.checkScam();
      const available = this.balance.amount / 10 ** this.balance.precision;
      const minAmount = this.gateways[this.protocol.type][this.symbol].minAmount / 10 ** this.gateways[this.protocol.type][this.symbol].precision;
      this.isValid.amount = available >= this.amount && this.amount > minAmount;
      this.amountToReceive = (this.amount * 1 - this.gateways[this.protocol.type][this.symbol].gateFee * 1);
      if (this.amountToReceive < 0) {
        this.amountToReceive = 0;
      }
    },
    async checkAddress() {
      if (!this.address) {
        return
      }
      await this.checkScam();
      this.isValid.address = null;
      try {
        this.address = this.address.trim();
        this.address  = this.address.replace(" ","");
        this.isValid.address = (await this.$store.dispatch('wallet/checkAddress', {
          gate: this.protocol.type,
          coin: this.gateways[this.protocol.type][this.symbol]['walletType'],
          address: this.address
        })).isValid
      } catch (e) {
        console.log('checkAddress', e)
      }
    },
    async enterAddress() {
      if (this.address) {
        this.address = this.address.trim();
        this.address  = this.address.replace(" ","");
        await this.checkAddress();
      }
    },
    async setProtocol(type) {
      this.protocol.type = type;
      this.memo = null;
      await this.cleanUp();
      /*
      this.address = null;
      this.memo = null;
      this.amount = null;
      this.isValid = {
          address: null,
          amount: null,
      };
       */

      const storedAddress = await this.$store.getters['wallet/addressesWithdraw'][type][this.symbol];
      if (storedAddress) {
        this.address = storedAddress.address;
        this.memo = storedAddress.memo;
      }
      if (this.address) {
        await this.checkAddress();
      }

      if (type === 'coins') {
        this.protocol.title = 'Native Blockchain';
        this.w_memo = this.gateways.coins[this.symbol].backingCoin.toLowerCase() + ":";
      }
      if (type === 'eth') {
        this.protocol.title = 'Ethereum network';
        this.w_memo = this.gateways.eth[this.symbol].backingCoin.toLowerCase() + ":";
      }
      if (type === 'eos') {
        this.protocol.title = 'EOS Chain';
        this.w_memo = this.gateways.eos[this.symbol].backingCoin.toLowerCase() + ":";
      }
      if (type === 'bsc') {
        this.protocol.title = 'Binance Smart Chain [BSC]';
        this.w_memo = "bsc:" + this.gateways.bsc[this.symbol].backingCoin.toLowerCase() + ":";
      }
      if (type === 'waves') {
        this.w_memo = this.gateways.waves[this.symbol].backingCoin.toLowerCase() + ":";
        this.protocol.title = 'WAVES Platform';
      }
    },
    async defaultProtocol(asset) {
      if (asset === 'BTS') {
        await this.setProtocol('core');
        return;
      }
      if (this.gateways.coins[asset]) {
        await this.setProtocol('coins');
        await eventBus.emit('set:asset', {
          asset: asset,
          protocol: 'coins',
          op: 'withdraw'
        });
        return;
      }
      if (this.gateways.eth[asset]) {
        await this.setProtocol('eth');
        await eventBus.emit('set:asset', {
          asset: asset,
          protocol: 'eth',
          op: 'withdraw'
        });
        return;
      }
      if (this.gateways.eos[asset]) {
        await this.setProtocol('eos');
        await eventBus.emit('set:asset', {
          asset: asset,
          protocol: 'eos',
          op: 'withdraw'
        });
        return;
      }
      if (this.gateways.bsc[asset]) {
        await this.setProtocol('bsc');
        await eventBus.emit('set:asset', {
          asset: asset,
          protocol: 'bsc',
          op: 'withdraw'
        });
        return;
      }
      if (this.gateways.waves[asset]) {
        await this.setProtocol('waves');
        await eventBus.emit('set:asset', {
          asset: asset,
          protocol: 'waves',
          op: 'withdraw'
        });
        return;
      }
    },
  },
  watch: {
    '$route.params.symbol': {
      handler: async function (asset) {
        if (!this.$root.pin) {
          await this.$router.push('/auth-pages/login');
        } else {
          this.symbol = asset;
          await this.defaultProtocol(asset);
          await this.$store.dispatch('accounts/updateBalances', {
            userId: this.$root.account.account.id,
            lpId: "1.19.181"
          });
          await this.$store.dispatch('wallet/gateways');
        }

      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    scamAccounts() {
      return this.$store.getters['wallet/scamAccounts'] || {};
    },
    balance() {
      return this.$store.getters['accounts/balances'][this.gateways[this.protocol.type][this.symbol].assetId] || null;
    },
    balanceBts() {
      return this.$store.getters['accounts/portfolio']['1.3.0'].amount / 10 ** 5 || 0;
    },
    gateways() {
      return this.$store.getters['wallet/gateways'];
    },
  },
  async created() {
    await this.defaultProtocol(this.$route.params.symbol);
    await this.checkScam();
  }
}
</script>

<style>

</style>
